* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

.teacherContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;

	@include smalltablet() {
		grid-template-columns: 1fr 1fr;
	}

	@include mobile1() {
		grid-template-columns: 1fr;
	}

	.teacher {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 20px;
		background-color: #f9f9f9;
		border-radius: 8px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

		.teacherImage img {
			width: 200px;
			height: 200px;
			border-radius: 50%;
		}

		.teacherName {
			margin-top: 10px;
			font-weight: bold;
			color: #ff8c33;
		}
	}
}
