* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}
@mixin normal {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

// courses.js
.courses_page {
  // border: 1px solid red;
  width: 100%;
  // padding: 0rem 3rem;
  position: relative;

  .search_rgid {
    .search{
      margin-top: 1.2rem;

    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include mobile1() {
      grid-template-columns: 1fr;
      h3 {
        text-align: left;
      }
    }
    @include mobile() {
      grid-template-columns: 1fr;
      h3 {
        text-align: left;
      }
    }
  }

  h3 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 0.7rem;
    @include mobile(){
      font-size: 1rem;
    }
  }
  hr {
    border: 1px solid #d9d9d9;
    margin: 2rem 0;
    @include mobile(){
      margin: 1rem 0;
    }
  }

  .filter_data {
    // border: 1px solid red;
    display: grid;
    grid-template-columns: 1fr 3fr;
    @include normal() {
      .select-category {
        display: none;
      }
    }

    @include tablet() {
      grid-template-columns: 1fr;
      .filter_container {
        display: none;
      }
    }
  }

  .select-category {
    select {
      padding: 0.4rem 0.6rem;
      border: 1.5px solid black;
      margin-bottom: 0.8rem;
      border-radius: 10px;
      font-size: 0.9rem;
    }
  }

  .filter_container {
    // width: 80%;
    height: 418px;
    border: 1px solid #8a8a8a;
    margin-right: 2rem;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    overflow: auto;

    h4 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    
    .data-title-wrapper {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
    }
    
    .data-title {
      word-wrap: break-word; 
      // margin-left: 8px; 
    }
    
    input[type="radio"] {
      margin-top: 4px; 
    }
    

    input {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin-right: 1rem;
      cursor: pointer;
      color: #000;
    }
  }

  .grid_image {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @include mobile1() {
      grid-template-columns: 1fr 1fr;
    }
    @include mobile() {
      grid-template-columns: 1fr;
    }

    .course_image {
      // border: 1px solid red;
      // height: 470px;
      height: 418px;
      background-color: #f5f5f5;
      margin-bottom: 1rem;
      position: relative;
      border-radius: 10px;

      .display_center {
        display: flex;
        justify-content: center;

        img {
          // width: 230px;
          // height: 225px;
          width: 205px;
          height: 200px;
          border-radius: 50%;
          padding: 0.7rem;
        }
      }

      h6 {
        width: 90%;
        margin: 1rem;
        padding: 1rem;
        color: #fff;
        font-size: 1.1rem;
        background-color: #fd6a02;
        text-align: center;
        position: absolute;
        bottom: 0;
      }
    }
  }

  .course_image:hover {
    h6 {
      display: none;
    }
  }

  .course_image:hover .hide_show {
    opacity: 1;
    visibility: visible;
  }
  .hide_show {
    height: 52%;

    background-color: #fd6a02;
    padding: 1rem;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.5s ease;
    border-radius: 0 0 10px 10px;

    .para_btn {
      height: 100%;
      position: relative;

      .title {
        .course_title {
          font-size: 1.1rem;
          font-weight: 600;
          color: #fff;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .course_description {
        // width: 230px;
        height: 70%;
        font-size: 0.9rem;
        text-align: justify;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .buttum {
        // border:  1px solid red;
        display: flex;
        justify-content: space-between;
        // flex-direction: column;
        // justify-content: flex-end;

        .fee{
          display: flex;
          color: #fff;
          font-weight: 600;
          margin-top: 8px;

          span{
            font-size: 1.1rem;
          }

        }

        button {
          margin-top: 7px;
          padding: 5px;
          background-color: #fff;
          color: #fd6a02;
          border-radius: 4px;
          cursor: pointer;
          border: none;
          outline: none;
          align-self: flex-end;
        }
      }
    }
  }

  //pagination course
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .pagination_prev {
      text-align: center;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin: 1rem;
      background-color: #eeeeee;
      cursor: pointer;

      button {
        width: 100%;
        height: 100%;
        background-color: #eee;
        color: #000;
        border: none;
        outline: none;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .numbers {
      margin: 1rem 0rem;
      button {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
