
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



// for responsive
@mixin mobile {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @mixin mobile1 {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin smalltablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @mixin tablet {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  
//VacancyDetails.jsx
.vacancy_details {
    // padding: 0 3rem;
  
    .image {
      width: 100%;
      margin-bottom: 0.5rem;
      img {
        width: 100%;
        height: 375px;
  
        @include tablet() {
          height: 300px;
        }
        @include smalltablet() {
          height: 300px;
        }
        @include mobile() {
          height: 400px;
        }
      }
    }
  
    .title_details {
      h5 {
        font-size: 24px;
        font-weight: 500;
        font-family: Poppins;
        text-align: justify;
        margin-bottom: 0.15rem;

        @include mobile(){
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  
    .vacancy_description {
      margin-bottom: 2rem;
      .vacancy_details {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        // line-height: 24px;
        padding: 0;
      }
    }
  }
  