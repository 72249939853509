*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


// for responsive
@mixin mobile {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @mixin mobile1 {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin smalltablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @mixin tablet {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }


//MyCourses.jsx
.mycourse_container {
    h3 {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      padding-top: 0.7rem;
      margin-bottom: 1rem;
    }
    .not_sub {
      text-align: center;
      margin: 5rem 0;
  
      h5 {
        font-size: 1.5rem;
        color: #fd6a02;
      }
    }
  }
  .grid_mycourse {
    display: grid;
    gap: 2rem;
    // margin: 0 3rem;
    margin-bottom: 3rem;
  
    .mycourse {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 2rem;
      padding: 1rem 2.5rem;
      width: 100%;
      height: 265px;
      background-color: #f5f5f5;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  
      @include smalltablet() {
        grid-template-columns: 1fr;
        height: 530px;
      }
      @include mobile1() {
        grid-template-columns: 1fr;
        height: 530px;
      }
      @include mobile() {
        grid-template-columns: 1fr;
        height: 550px;
      }
    }
    .mycourse:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
    .my_img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 230px;
      }
    }
    .course_des {
      h4 {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .myCourse_desription {
        height: 70%;
        text-align: justify;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      button {
        width: 130px;
        padding: 0.5rem;
        float: right;
        margin-top: 0.4rem;
        border: none;
        outline: none;
        background-color: #fd6a02;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  