* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

//FullDetailsCourse.jsx
.ourse_details {
  width: 100%;
  position: relative;
  // overflow-x: hidden;
  h3 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 0.7rem;

    @include mobile1() {
      font-size: 1.1rem;
      padding-top: 0.4rem;
    }
  }
  hr {
    border: 1px solid #d9d9d9;
    margin: 2rem 0;
  }

  .main_course {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;

    @include mobile1() {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    //1 part
    .description {
      .title_fee {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        @include mobile() {
          flex-direction: column;
        }

        h5,
        h6 {
          font-size: 1.1rem;
          text-align: left;
        }
        h6 {
          color: #fd6a02;
        }
      }

      .course_image {
        img {
          // width: 100%;
          // height: auto;

          height: 20rem;
          width: auto;

          @include mobile() {
            width: 100%;
            height: auto;
          }
        }
      }

      a {
        text-decoration: none;
      }

      .overButton {
        margin: 1rem 0;
        .active {
          background-color: #fd6a02;
          color: white;
        }
        button {
          border: 2px solid #fd6a02;
          background-color: #fff;
          color: #fd6a02;
          border-radius: 10px;
          padding: 0.5rem 1rem;
          cursor: pointer;
          margin-right: 20px;
          font-size: 1.2rem;
        }
        button:hover {
          color: #fff;
          background-color: #fd6a02;
        }
      }
    }

    //2 part
    .videos {
      max-width: 100%;
      min-height: 400px;
      border: 2px solid #8e8e8e;
      border-radius: 10px;
      padding: 0.5rem;

      .lectures_no {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        margin-top: 2rem;
        .lecture {
          font-size: 1rem;
          text-align: left;
        }
      }
      hr {
        width: 90%;
        margin: 0.5rem 1rem;
      }
      .videos_no {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        .avilable_videos {
          font-size: 1rem;
          text-align: left;
        }
      }
      button {
        margin: 2rem 1rem;
        padding: 0.8rem;
        width: 90%;
        background-color: #fd6a02;
        color: #fff;
        border: none;
        outline: none;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 5px;
      }
    }

    .react-player {
      // width: 100%;
      // height: 100%;

      width: 100% !important;
      height: auto !important;
    }
  }
  .upcommingExam {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
  }
}
