@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

.carousel .carousel-status {
	display: none;
}
.banner {
	width: 100%;
	// margin-top: 6rem;
	margin-top: 2.4rem;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: red;
	font-size: 1.2rem;

	@include mobile() {
		margin-top: -1rem;
	}
	img {
		@include tablet() {
			width: 80%;
			height: 550px;
		}
		@include smalltablet() {
			width: 70%;
			height: 520px;
		}
		@include mobile1() {
			width: 60%;
			height: 470px;
		}
		@include mobile() {
			width: 50%;
			height: 450px;
		}
		object-fit: cover;
		width: 100%;
		height: 600px;
	}
}
