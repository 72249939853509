* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

//Login.js
.main_container {
  // border: 1px solid red;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @include mobile() {
    height: 90vh;
    margin-top: 2rem;
    margin-bottom: 0rem;

    // height: 75vh;
  }

  .back_login {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    @include mobile1() {
      width: 90%;
      // height: 60vh;
    }

    button {
      padding-top: 0.5rem;
      border: none;
      background-color: #fff;
      font-size: 1rem;
      cursor: pointer;
    }
  }
  .loging_container {
    width: 50%;
    border: 2px solid #fd6a02;
    // margin-top: 1rem;
    padding: 1rem 7rem;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px;

    @include tablet() {
      width: 60%;
    }
    @include smalltablet() {
      width: 70%;
    }
    @include mobile1() {
      width: 80%;
      padding: 1rem 1.5rem;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    @include mobile() {
      width: 90%;
      padding: 1rem 1.5rem;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .label_input {
		
      margin: 1rem 0;

    }

    .logInput {
      width: 100%;
      height: 2.5rem;
      margin: 1rem 0 0 0;
      border: 1px solid #fd6a02;
      border-radius: 5px;
      outline: none;
      padding-left: 0.5rem;
    }
	.password_wrapper{
		display: flex;
	}

    button {
      width: 100%;
      height: 2.5rem;
      margin: 1.5rem 0 1rem 0;
      border: 1px solid #fd6a02;
      background-color: #fd6a02;
      border-radius: 5px;
      cursor: pointer;
      color: #fff;
      font-size: 1rem;
      text-align: center;
      border: none;
      outline: none;
    }

    .text {
      text-align: center;
      .forget_password {
        text-align: center;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      button {
        margin-top: 0;
        border: none;
        outline: none;
        background-color: #fff;
        color: #fd6a02;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    span {
      color: red;
    }
  }
}
