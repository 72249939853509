// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
//ArticlesDetails.jsx
.articlesDetails {
	.main_article {
		.article_img {
			margin-bottom: 0.5rem;
			img {
				// border: 1px solid red;
				width: 100%;
				height: 375px;
			}
		}
		.article_para {
			h5 {
				font-size: 24px;
				font-weight: 500;
				font-family: Poppins;
				text-align: justify;
			}
			.details_article {
				width: 100%;
				height: auto;
				font-size: 16px;
				font-weight: 400;
				text-align: justify;
				margin-bottom: 2rem;
			}
		}
	}
	.article-socialMedia {
		display: flex;
		padding-bottom: 1rem;
		.article-socialMediaShare {
			font-size: 1.2rem;
			color: gray;
			margin-right: 1rem;
		}
	}
}
