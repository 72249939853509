* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

//ArticlesForHomePage
.article_main_container {
  // border: 2px solid red;
  margin: 2rem 0 0 0;
  width: 100%;

  .heading_articles {
    // border: 1px solid red;
    h3 {
      font-family: "Maiden Orange", serif;
      font-style: normal;
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
      margin: 1rem 0;

      @include mobile1(){
        font-size: 26px;
        margin: 0.5rem 0;
      }
    }
  }

  .articlesForHomepage {
    // border: 1px solid red;
    width: 100%;
    height: auto;
    background-color: #f3f3f3;
    padding: 2rem;
    text-align: center;

    .article_container {
      // border: 1px solid red;
      cursor: pointer;

      img {
        @include tablet(){
          width: 80%;
        }
        @include smalltablet(){
          width: 90%;
        }
        @include mobile1(){
          width: 90%;
        }
        @include mobile(){
          width: 100%;
        }
        width: 50%;
        height: 325px;
      }
      h3 {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: #fe0100;
        margin: 0.6rem 0rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .article_description {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: justify;
        color: #888;

        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .view_more {
      margin: 1rem 0;
      text-align: right;

      button {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #656565;
        color: #fd6a02;
        cursor: pointer;
      }

      button:hover {
        border: 1px solid #fd6a02;
        background-color: #fd6a02;
        color: #fff;
        border: none;
        outline: none;
      }
    }
  }

  .make_gride {
    .for_pagination {
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // align-items: center;
      // gap: 2rem;
      margin: 2rem 2.5rem;

      @include mobile(){
        margin: 1rem;
      }

      .prev_next {
        justify-self: end;
        button {
          width: 47px;
          height: 47px;
          font-size: 1.2rem;
          background-color: #4483ff;
          border-radius: 5px;
          border: 1px solid #4483ff;
          color: #fff;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
      .next {
        justify-self: start;
        button {
          width: 47px;
          height: 47px;
          font-size: 1.2rem;
          background-color: #4483ff;
          border-radius: 5px;
          border: 1px solid #4483ff;
          color: #fff;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
      button:hover {
        background-color: #fd6a02;
      }
    }
  }
}
