* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

//MycourseDetails.jsx
.myCourseDetails {
	// border: 1px solid red;
	// margin: 0 3rem;
	margin-bottom: 3rem;

	.image {
		height: 375px;
		// height: auto;
		object-fit: cover; //change here object-fit
		margin-bottom: 3rem;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.body_videos, .youtube_url {
		// border: 1px solid red;
		display: grid;
		grid-template-columns: 3fr 1fr;
		gap: 2rem;
		margin-bottom: 2rem;

		@include tablet() {
			grid-template-columns: 1fr 1fr;
		}
		@include smalltablet() {
			grid-template-columns: 1fr;
		}
		@include mobile1() {
			grid-template-columns: 1fr;
		}
		@include mobile() {
			grid-template-columns: 1fr;
		}

		.title_para {
			// border: 1px solid red;
			width: 100%;
			height: auto;

			.title {
				font-family: Poppins;
				font-size: 20px;
				font-weight: 600;
				line-height: 32px;
				text-align: left;

				@include mobile1() {
					font-size: 18px;
				}
			}

			.para {
				font-family: Poppins;
				font-size: 18px;
				font-weight: 400;
				line-height: 28px;
				text-align: justify;
			}
		}

		// videoes section
		.videos {
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			padding: 1rem 1.5rem;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
				rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
			height: 300px;
			overflow-x: auto;

			.videos-text {
				margin-bottom: 0.5rem;
				display: flex;
				justify-content: center;
				h4 {
					font-family: Poppins;
					font-size: 20px;
					font-weight: 600;
					line-height: 36px;
					text-align: center;

					@include mobile1() {
						font-size: 18px;
					}
				}
				.title_icon {
					font-size: 2rem;
					padding-left: 15px;
					color: red;
				}
			}
		}

		.vieos_container {
			display: flex;
			margin-bottom: 1rem;
			// width: 300px;

			.inner_grid {
				// border: 1px solid red;
				display: flex;
				background-color: #f5f5f5;
				width: 100%;
				border-radius: 10px;
				padding: 15px;
				padding-bottom: 10px;
				gap: 10px;
				cursor: pointer;

				.body_icon {
					font-size: 1.5rem;
					color: red;
					// padding-bottom: 15px;
				}

				.videos_title {
					width: 220px;
					font-size: 1rem;
					color: #7f7a7a;
					text-decoration: none;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					@include mobile() {
						width: 150px;
					}
				}

				.play_icon {
					font-size: 1.5rem;
					color: #6b6b6b;
				}
			}

			.inner_grid:hover {
				box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
					rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
			}

			a {
				text-decoration: none;
			}
		}
		.download-button {
			color: gray;
			font-size: 2rem;
			padding: 0.4rem;
			cursor: pointer;
		}
	}


	.extra{
		border: 1px solid red;
		width: 410px;

		@include mobile(){
			width: 350px;
		}
	}

	.youtube_url{
		display: flex;
  		justify-content: flex-end;


		.inner_grid{
			width: 100%;
		}

	}

	//note/fdf section
	h4 {
		font-family: Poppins;
		font-size: 20px;
		font-weight: 600;
		line-height: 32px;
		text-align: left;
		margin-bottom: 0.5rem;

		@include mobile1() {
			font-size: 18px;
		}
	}

	.for_note {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-row-gap: 1rem;
		grid-column-gap: 2.5rem;
		margin-bottom: 2rem;

		@include tablet() {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@include smalltablet() {
			grid-template-columns: 1fr 1fr;
		}
		@include mobile1() {
			grid-template-columns: 1fr;
		}
		@include mobile() {
			grid-template-columns: 1fr;
		}

		a {
			text-decoration: none;
		}

		.note_grid {
			display: flex;
			border: 2px solid rgba(0, 0, 0, 0.1);
			padding: 0.4rem;
			background-color: #f5f5f5;
			cursor: pointer;
			border-radius: 5px;

			.pdf_icon {
				font-size: 1.5rem;
				color: #ff0000;
			}
			.pdf_title {
				div {
					max-width: 220px;
					font-family: Poppins;
					font-size: 16px;
					font-weight: 400;
					line-height: 28.18px;
					color: #7f7a7a;
					text-align: left;
					padding-left: 1rem;
					white-space: nowrap; /* Prevent line breaks */
					overflow: hidden; /* Hide overflow */
					text-overflow: ellipsis; /* Display ellipsis */
				}
			}
		}
		.note_grid:hover {
			border: none;
			box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
				rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
		}
	}
}
