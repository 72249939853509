* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
//Article.jsx
.search_header {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	@include smalltablet() {
		grid-template-columns: 1fr;
	}

	h3 {
		text-align: center;
		font-size: 1.4rem;
		font-weight: 600;

		@include mobile() {
			font-size: 1rem;
			text-align: left;
			margin-top: 0.5rem;
		}
	}
}
.articles {
	h3 {
		margin-bottom: 1rem;
		text-align: center;
		font-size: 1.4rem;
		font-weight: 600;

		@include mobile() {
			font-size: 1rem;
			// margin-bottom: 0;
		}
	}

	.article_main {
		display: grid;
		grid-template-columns: 3fr 2fr;
		gap: 2rem;
		// column-gap: 1rem;
		border-radius: 5px;

		@include mobile1() {
			grid-template-columns: 1fr;
		}
		@include mobile() {
			grid-template-columns: 1fr;
			// gap: 1rem;
		}

		.article_right {
			display: flex;
			align-items: center;
			width: 100%;
			text-align: center;
			justify-content: center;
			color: red;
			font-size: 1.2rem;
			border: 1px solid #bababa;
			border-radius: 8px;

			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px;
				position: relative;
				&:hover {
					opacity: 90%;
				}
			}
			.article_right_details {
				padding: 0.2rem 0.5rem;
				border-radius: 10px;
				position: absolute;
				margin: 1rem 1.5rem;
				.article_right_title {
					font-weight: 500;
					font-size: 1.2rem;
				}
			}
		}

		.article_left {
			width: 100%;
			padding: 0.8rem;
			border: 1.5px solid #bababa;
			border-radius: 5px;

			.article_heading {
				// border: 1px solid red;
				font-size: 20px;
				text-align: center;

				@include mobile() {
					font-size: 18px;
				}
			}

			.for_element {
				display: flex;
				gap: 10px;
				padding: 0.9rem 0.2rem;
				border-radius: 10px;
				.image img {
					width: 135px;
					height: 80px;
					border-radius: 10px;
				}
				.article_details {
					margin-top: 0;
					font-size: 16px;
					text-align: justify;

					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.article_left_list {
					width: 68%;
				}
				.article_left_top {
					display: flex;
					justify-content: space-between;
					@include smalltablet() {
						flex-direction: column;
					}
					.article_title {
						font-weight: 600;
					}
					.article_time {
						color: gray;
						font-size: 0.9rem;
					}
				}
				&:hover {
					background-color: rgb(237, 237, 236);
					cursor: pointer;
				}
			}
			hr {
				margin: 0rem 0;
				color: #bababa;

				@include mobile() {
					margin: 1rem 0;
				}
			}
		}
	}

	//pagination for articles
	.pagination {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;

		.pagination_prev {
			text-align: center;
			width: 55px;
			height: 55px;
			border-radius: 50%;
			margin: 1rem;
			background-color: #eeeeee;
			cursor: pointer;

			button {
				width: 100%;
				height: 100%;
				background-color: #eee;
				color: #000;
				border: none;
				outline: none;
				border-radius: 50%;
				cursor: pointer;
			}
		}
		.numbers {
			margin: 1rem 0rem;
			button {
				width: 55px;
				height: 55px;
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}
}
