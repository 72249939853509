@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
@mixin normal {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}
.courseList-category {
	border: 1.5px solid black;
	padding: 1rem;
	border-radius: 20px;
	h4 {
		font-size: 1.1rem;
		font-weight: 700;
		text-align: center;
	}
	.data-title-wrapper {
		margin-bottom: 0.4rem;
		cursor: pointer;
		.data-title {
			margin-left: 1rem;
		}
	}
}
@include normal() {
	.select-category {
		display: none;
	}
}

@include tablet() {
	.courseList-category {
		display: none;
	}
}
