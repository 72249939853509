// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

.exam-main {
	margin: 2rem;
	margin-top: 7rem;

	@include mobile() {
		margin: 6rem 1rem;
	}
	.search_header {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		@include smalltablet() {
			grid-template-columns: 1fr;
		}

		h3 {
			text-align: center;
			font-size: 1.4rem;
			font-weight: 600;

			@include mobile() {
				font-size: 1rem;
				text-align: left;
				margin-top: 0.5rem;
			}
		}
		.search {
			margin-top: 0.3rem;
		}
		.weekly-exams {
			justify-self: end;
			margin-right: 0.6rem;
			span {
				margin-right: 0.8rem;
			}
			button {
				padding: 0.2rem 0.8rem;
				background-color: white;
				border: 1.5px solid #fd6a02;
				color: #fd6a02;
				border-radius: 10px;
				font-size: 0.9rem;
				font-weight: 600;
				cursor: pointer;
			}
		}
	}
}

hr {
	border: 1px solid #d9d9d9;
	margin: 2rem 0;

	@include mobile() {
		margin: 0.5rem 0;
	}
}
.exam-submain {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-gap: 1.5rem;
	@include tablet() {
		grid-template-columns: 1fr;
	}
}

.exam-outer {
	display: flex;
	flex-wrap: wrap;
}

.exam-inner {
	display: grid;
	grid-template-columns: 1fr 2fr;
	width: 100%;
	background-color: #fff;
	margin: 0rem 0rem 1.5rem;
	padding: 1rem 2.5rem;

	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	// height: 265px;
	// background-color: #f5f5f5;
	// border-radius: 8px;
	// overflow: hidden;
	// margin: 1rem 0;
	// padding-bottom: 2rem;
	// gap: 0rem;
	// box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

	@include smalltablet() {
		grid-template-columns: 1fr;
		height: 450px;
	}
	@include mobile1 {
		grid-template-columns: 1fr;
		height: 440px;
	}
	@include mobile() {
		grid-template-columns: 1fr;
		height: 430px;
		padding: 1rem;
	}
}

.exam-inner:hover {
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.exam-image {
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 250px;
		object-fit: cover; // also change here

		// height: 100%;
		// height: auto;
		// max-height: 20rem;
		// @include mobile {
		// 	// max-height: 15rem;
		// }
		// @include mobile(){
		// 	width: 100%;
		// 	height: 100%;
		// }

		@include smalltablet() {
			width: 100%;
			height: 250px;
		}
	}
}

.makeFlex {
	display: flex;
	justify-content: space-between;

	.exam-name12 {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		color: #333;
	}
}

.exam-content {
	margin-left: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include smalltablet() {
		margin-left: 0;
	}

	.exam-name {
		color: #fd6a02;
		font-size: 1.4rem;
		font-weight: 600;

		@include smalltablet() {
			font-size: 1rem;
			font-weight: 600;
		}
	}

	.exam-courseTitle,
	.examPercent {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		color: #333;
	}

	.exam-bottom {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		justify-content: space-between;
		margin-top: auto;
		margin-right: 4rem;
		@include smalltablet() {
			// i change from here yusuf
			grid-template-columns: 1fr;
			width: 150px;
		}
		@include mobile1 {
			grid-template-columns: 1fr;
		}
		@include mobile() {
			grid-template-columns: 1fr;
		}

		span {
			display: flex;
			align-items: center;
			font-size: 1rem;
			color: black;
		}

		button {
			background-color: #fd6a02;
			color: #fff;
			padding: 0.6rem 1.2rem;
			border: none;
			border-radius: 10px;
			font-size: 0.8rem;
			font-weight: 500;
			cursor: pointer;
			transition: background-color 0.3s ease;
			margin-bottom: 0.5rem;

			// box-shadow: 0px 6.68px 17.36px 0px #00000040;
			// @include mobile1() {
			// 	margin-top: 1.2rem;
			// }

			&:hover {
				background-color: #e65b00;
				box-shadow: 0px 6.68px 17.36px 0px #00000040;
			}
		}
	}
}

.examDetails-main {
	margin: 0 auto;
	padding: 2rem;
	border: 1px solid #e3e6e8;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff; // Background color for the container
	.examDetails-topic {
		display: flex;
		justify-content: space-between;
		margin: 0 1rem;
		.examDetails-timer {
			font-size: 1.2rem;

			.examDetails-yourTimer {
				font-size: 0.9rem;
			}
		}
	}

	h2 {
		color: #fd6a02;
		font-size: 1.5rem;
		margin-bottom: 0.8rem;
		text-align: center;
	}

	.error {
		color: #ff4c4c; // Error message color
		font-size: 1rem;
		margin-bottom: 1rem;
		text-align: center;
	}

	.examDetails-outer {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		background-color: #f5f5f5;
		padding: 1.5rem 1.2rem;
		border-radius: 12px;

		.examDetails-inner {
			border-bottom: 1px solid #e3e6e8; // Divider between questions
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;

			.exam-question {
				font-weight: 500; // Bold for question text
				font-size: 1.1rem; // Font size for the question
				margin-bottom: 0.5rem; // Space between question and options
			}
			.exam-question-image {
				img {
					height: 18rem;
					width: auto;
					margin: 0.5rem;
				}
			}

			.exam-options {
				display: flex;
				flex-direction: column;
				gap: 0.75rem; // Gap between each option

				.exam-option {
					display: flex;
					align-items: center;
					font-size: 1rem; // Option text size

					input[type="radio"] {
						margin-right: 0.5rem; // Space between radio button and label
					}

					label {
						cursor: pointer; // Pointer cursor for labels
					}
				}
			}
		}
	}

	.examDetails-button {
		button {
			width: auto;
			background-color: #fd6a02;
			color: #fff;
			padding: 0.6rem 2rem;
			border: none;
			border-radius: 10px;
			font-size: 1rem;
			font-weight: 500;
			margin: 1.2rem 0;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #e65b00;
			}

			&:disabled {
				background-color: #ccc;
				cursor: not-allowed;
			}
		}
	}
	.examDetails-pagination {
		display: flex;
		button {
			background-color: #fe7614;
			color: #fff;
			padding: 0.3rem 1rem;
			border: none;
			border-radius: 10px;
			font-size: 0.8rem;
			font-weight: 500;
			margin: 1.2rem 0;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #e65b00;
			}

			&:disabled {
				background-color: #ccc;
				cursor: not-allowed;
			}
		}
	}
}
