@import url("https://fonts.googleapis.com/css?family=Poppins");
* {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}
.contact-main {
  h3 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1rem;

    @include mobile(){
      font-size: 1rem;
    }
  }
  .contact-text {
    margin: 1rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: justify;
    line-height: 1.5rem;
  }
}
.contact-submain {
  margin: 1rem 0rem;
  padding: 2rem 1rem;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  @include mobile() {
    grid-template-columns: 1fr;
  }
  .contact-submain-inner {
    border-right: 1px solid #adadad;
    .contact-submain-text {
      font-size: 0.9rem;
    }
  }
  :last-child {
    border: none;
  }
  .contact-submain-icon {
    color: #00c4dd;
    font-size: 1.8rem;
    font-weight: 600;
  }
  #location-icon {
    color: #35bfa2;
  }
  #email-icon {
    color: #ee9900;
  }
}
.contact-map {
  margin: 1rem 0rem;
  h4 {
    font-size: 0.8rem;
    font-weight: 400;
  }
}
.contact-below {
  margin: 1.5rem 0rem;
  h3 {
    font-size: 1.2rem;
    color: #fd6a02;
    margin: 1rem;
  }
  .errors {
    color: red;
    text-align: center;
    font-size: 0.9rem;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input,
  textarea {
    border-radius: 20px;
    border: 2px solid #c3c3c3;
    padding: 1.5rem;
    width: 100%;
    background-color: #f5f5f5;
  }
  .form-top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin: 1rem 0;

    @include mobile1() {
      grid-template-columns: 1fr;
    }
    @include mobile() {
      grid-template-columns: 1fr;
    }
  }
  .form-below {
    margin: 1.5rem 0;
    textarea {
      height: 20rem;
      text-align: left;
      border: 1px solid #c3c3c3;
    }
  }
  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background-color: #4483ff;
      padding: 1rem 2rem;
      border: none;
      border-radius: 70px;
      color: white;
      margin: 0.2rem;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
