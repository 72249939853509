*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


// for responsive
@mixin mobile {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @mixin mobile1 {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin smalltablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @mixin tablet {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  
// Register.js
.form {
    width: 100%;
    height: auto;
    display: grid;
    place-items: center;
    position: relative;
  
    h3 {
      text-align: center;
      margin-top: 2rem;
    }
  
    .register_container {
      @include tablet() {
        width: 60%;
        padding: 1rem 6rem;
      }
      @include smalltablet() {
        width: 70%;
        padding: 1rem 4rem;
      }
      @include mobile1() {
        width: 80%;
        padding: 1rem 3rem;
      }
      @include mobile() {
        width: 90%;
        padding: 1rem 2rem;
      }

      width: 70%;
      height: auto;
      border: 2px solid #fd6a02;
      padding: 2rem 10rem;
      margin-top: 1rem;
      margin-bottom: 3rem;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 40px;

      @include mobile1(){
        border-radius: 20px;
      }
  
      .uper_part {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-evenly;
        column-gap: 1rem;

        @include mobile1(){
          grid-template-columns: 1fr;
        }
      }

      .uper_part1 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-evenly;
        column-gap: 1rem;

        @include mobile1(){
          grid-template-columns: 1fr;
        }
      }
  
      .registerNeme {
        width: 100%;
        height: 2.5rem;
        margin: 1rem 0;
        font-size: 0.9rem;
        padding-left: 0.5rem;
        border: 1.5px solid #fd6a02;
        outline: none;
        border-radius: 5px;
      }
      .envision {
        width: 100%;
        height: 2.5rem;
        margin: 1rem 0;
        font-size: 0.9rem;
        padding-left: 0.5rem;
        border: 1.5px solid #fd6a02;
        outline: none;
        border-radius: 5px;
        // color: #949494;
        color: #000;
      }
      button {
        background-color: #fd6a02;
        cursor: pointer;
        color: #fff;
      }
      span {
        color: red;
      }
      #mybutton {
        color: #fff;
      }
    }
    .have_accout {
      text-align: center;
      font-size: 1rem;
      button {
        border: none;
        outline: none;
        color: #fd6a02;
        background-color: #fff;
        text-decoration: underline;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  