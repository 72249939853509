* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main_dashboard {
    // padding: 1rem;
    .heading {
      font-size: 1.5rem;
      text-align: center;
      font-weight: bold;
      color: #fd6a02;
    }
  
    .subHeading {
      font-size: 1rem;
      font-weight: bold;
      padding-left: 1.5rem;
    }
  
    // Upper Part
    .main_box, .main_box1 {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      gap: 1rem;
  
      &.main_box1 {
        justify-content: flex-start;
        padding-left: 1.5rem;
      }
  
      .box {
        width: 225px;
        height: 175px;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 15px;
        transition: transform 0.5s ease;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 1rem;
        text-align: center;
  
        .icon {
          font-size: 1.5rem;
          text-align: left;
          padding-bottom: 1rem;
        }
  
        .box_heading,
        .box_data {
          font-size: 1.1rem;
          text-align: left;
        }
  
        .box_data {
          padding-top: 1rem;
          font-weight: bold;
        }
      }
  
      .box:hover {
        transform: scale(1.1);
        // background-color: #F8F8F8;
        box-shadow: 0px 4px 8px 0px rgba(253, 106, 2, 0.3);
      }
    }
  
    // Lower Part
    .MainButtom {
      .subHeading {
        font-size: 1rem;
        font-weight: bold;
        padding-left: 1.5rem;
      }
  
      .upperPart {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1.5rem;
  
        .titleFlex {
          display: flex;
          gap: 1rem;

          .weekly,
          .monthly,
          .yearly {
            font-size: 1.2rem;
              cursor: pointer;
              gap: 1rem;
          }

          // .weekly,
          // .monthly,
          // .yearly:hover{
          // color: #1976d2;
          // }
          .active {
              font-weight: bold;
              color: #fd6a02;
          }
        }
      }
    }
  }
  