*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


// for responsive
@mixin mobile {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @mixin mobile1 {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin smalltablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @mixin tablet {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }


//ViewProfile.jsx
.view_container {
    // border: 1px solid red;
    width: 100%;
    display: grid;
    place-items: center;
    
    .outer_div {
      display: grid;
      place-items: center;
      border: 2px solid #fd6a02;
      width: 60%;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 40px;
      margin: 2rem 0;
  
      @include smalltablet() {
        width: 70%;
      }
      @include mobile1() {
        width: 80%;
      }
      @include mobile() {
        width: 90%;
      }
  
      .inner_div {
        border: 2px solid rgba(0, 0, 0, 0.1);
        background-color: #f9f9f9;
        width: 70%;
        border-radius: 8px;
        margin: 2rem 0;
  
        @include smalltablet() {
          width: 70%;
        }
        @include mobile1() {
          width: 80%;
        }
        @include mobile() {
          width: 90%;
  
          .view_containent {
            width: 80%;
          }
        }
  
        .chaild_element {
          padding-left: 2rem;
          padding-bottom: 1.5rem;
  
          h6 {
            font-size: 16px;
            padding: 0.5rem 0;
          }
          .view_containent {
            @include mobile() {
              width: 90%;
            }
            background-color: #ededed;
            width: 60%;
            padding: 0.8rem;
            border-radius: 8px;
          }
  
          button {
            @include mobile() {
              width: 90%;
            }
            width: 60%;
            padding: 0.8rem;
            background-color: #fd6a02;
            color: #fff;
            font-size: 1rem;
            border-radius: 8px;
            cursor: pointer;
            border: none;
            outline: none;
          }
        }
      }
    }
  }