@import url("https://fonts.googleapis.com/css?family=Poppins");

@import url("https://fonts.googleapis.com/css2?family=Maiden+Orange&display=swap");

* {
	margin: 0;
	padding: 0;
	font-family: Poppins;
}

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

main {
	margin-top: 6rem;
	padding: 0rem 2.5rem;

	@include mobile(){
		padding: 0rem 0.5rem;
	}

	@include mobile1(){
		padding: 0rem 1rem;
	}
}

