* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

.overviewMain {
	.syllaby {
		font-size: 1.1rem;
		text-align: left;
		font-weight: 600;
		color: #fd6a02;
	}

	.pdf_url{
		width: 70%;

		@include mobile1(){
			width: 100%;
		}
		
		.icon_grid {
			display: flex;
			width: 100%;
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 0.4rem;
			background-color: #f5f5f5;
			cursor: pointer;
			border-radius: 5px;
			margin: 0.5rem 0;
	
			.pdf_icon {
				font-size: 1.5rem;
				color: #ff0000;
				padding-top: 6px;
				@include mobile(){
					padding-top: 0;
				}
			}
			.pdf_title {
				font-family: Poppins;
				font-size: 16px;
				color: #333;
				text-align: left;
				padding-left: 0.5rem;
				font-weight: 400;
			}
			.pdf_title:hover {
				color: #fd6a02;
			}
		}
	}

	.details {
		h6 {
			font-size: 1.1rem;
			@include mobile1() {
				font-size: 1.1rem;
			}
		}
		.curriculum_details {
			font-size: 16px;
			line-height: 24px;
			text-align: justify;
		}
	}
}
