* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

//Vacancies/Notices
.vacancies {
	width: 100%;
	position: relative;

	h3 {
		font-size: 1.4rem;
		font-weight: 600;
		text-align: center;

		@include mobile() {
			font-size: 1rem;
		}
	}

	hr {
		border: 1px solid #d9d9d9;
		margin: 2rem 0;
		@include mobile() {
			margin: 1rem 0;
		}
	}

	.vacancy_grid {
		width: 100%;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-gap: 1rem;
		margin-bottom: 2rem;

		@include mobile() {
			grid-template-columns: 100%;
			margin-bottom: 1rem;
		}

		.main_vacancy{

			// display: grid;
			// grid-template-columns: 3fr 1fr;

			// .for_image{
			// 	width: 200px;
			// 	height: 200px;
			// }
		}

		.inner_vacancy {
			display: grid;
			grid-template-columns: 3fr 1fr;
			padding: 1rem 1.5rem;
			background-color: #f5f5f5;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border-radius: 10px;

			@include mobile1() {
				grid-template-columns: 100%;
				padding: 1rem;
			}

			a {
				text-decoration: none;
				// line-height: 20px;
			}

			.title {
				font-size: 1.2rem;
				font-weight: 600;
				text-transform: capitalize;
				color: #fd6a02;

				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.pdf_wrapper {
				display: flex;
				align-items: center;

				.pdf_icon {
					font-size: 1.1rem;
					color: #d93025;
					margin-right: 8px;
				}
				.icon {
					font-size: 1.1rem;
					color: #333;
				}
			}

			.vacancy_wrapper {
				display: flex;
				align-items: center;
				margin-bottom: 8px;

				.vacancy_icon {
					font-size: 2rem;
					font-weight: 600;
					padding-top: 8px;
					margin-right: 8px;
				}
				.link {
					font-size: 1.1rem;
					color: #333;
				}
			}

			.link:hover,
			.icon:hover {
				color: #fd6a02;
			}

			.for_image {
				width: 100%;
				height: 100%;
			  
				@include mobile1() {
				  width: 325px;
				  margin-top: 0.5rem;
				}
			  
				img {
				  width: 100%;
				  height: 100%;
				  transition: transform 0.3s ease;
				//   border-radius: 5px;
				
				  // Apply hover effect only on screens larger than 768px
				  @media (min-width: 768px) {
					&:hover {
					  transform: scale(1.5); // Scale up by 30% on hover
					  border-radius: 10px;
					}
				  }
				}
			}
		}
	}
}
