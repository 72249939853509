* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

@mixin menu() {
	position: absolute;
	background-color: #fff;
	box-shadow: rgba(78, 77, 77, 0.2) 0px 2px 8px 0px;
	z-index: 1;
	border-radius: 5px;
	overflow: auto;
}

@mixin menuli() {
	list-style: none;
	color: #000;
	font-size: 0.9rem;
	background-color: #fff;
	padding: 0.8rem 1.8rem;
	margin: 0.5rem;
}

body .navbar {
	font-family: Arial, sans-serif;
	// border: 1px solid red;
}

.navbar {
	// border: 1px solid red;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	padding: 1.5rem 3rem 1rem;

	position: fixed;
	top: 0;
	width: 100%;
	z-index: 2;

	@include mobile1() {
		padding: 1.5rem 1rem 1rem;
	}
}

.brand-title {
	a img {
		width: 55px;
		height: 55px;
		cursor: pointer;
	}
}

.navbar-links {
	height: 100%;
}

.navbar-links ul {
	display: flex;
	list-style: none;
}

.navbar-links li {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	min-width: 100px;
	padding: 15px;
	border: 2px solid #fd6a02;
	border-radius: 50%;
}

.navbar-links a {
	// color: white;
	color: #000;
	text-decoration: none;
	font-size: 18px;
}

.navbar-links a.active,
.navbar-links a:hover {
	color: #fd6a02;
}
// .navbar-links a:hover{
//   color: #fd6A02;
// }
.toggle-button {
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 21px;
}

.toggle-button .bar {
	height: 3px;
	width: 100%;
	background-color: white;
	border-radius: 10px;
}

.btn button {
	background-color: #fd6a02;
	font-weight: 500;
	font-size: 1.2rem;
	text-align: center;
	border: none;
	outline: none;
	padding: 0.4rem 2.5rem;
	border-radius: 35px;
	color: #fff;
	cursor: pointer;

	@include mobile() {
		width: 115px;
		font-size: 16px;
	}
}

// here for user name
.userWrapper {
	display: flex;
	.userName {
		margin-top: 9px;
		padding-right: 8px;
		span {
			color: #fd6a02;
		}
	}
}

.user {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	color: #656565;
	border: 1px solid #656565;
	font-size: 30px;
	font-weight: lighter;
	float: right;
	text-align: center;
	cursor: pointer;
	// color: #000;
}

.user {
	position: relative;
	display: inline-block;

	.login-menu {
		right: 5%;
		@include menu();
		// height: 6rem;
		height: 8rem;
		color: #656565;

		ul {
			display: grid;
			grid-template-columns: 100%;
			padding: 0.65rem;
			list-style: none;

			li {
				@include menuli();
				&:hover {
					// background-color: #fd6A02;
					border-radius: 5px;
				}
			}

			.login-menu-name {
				margin: 0.1rem;
				padding: 0.5rem;
				text-align: center;
				font-size: 1rem;

				a {
					text-decoration: none;
					color: #000;
				}
				&:hover {
					background-color: #fd6a02;
					a {
						color: #fff;
					}
				}
			}
		}
	}
}

// for dropdown menu on user icons and logout
.profile-dropdown {
	position: absolute;
	right: 20px;
	width: 150px;
	height: 130px;
	top: 80px;
	margin-right: 1.5rem;
	z-index: 2;
	background: #fff;
	color: #656565;
	border-radius: 10px;
	text-align: left;
	padding-left: 1.5rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	font-size: 1.1rem;
	z-index: 1;

	.dropdown-content {
		margin-top: 1.1rem;

		a {
			color: #656565;
		}
		a:hover {
			padding: 0.3rem;
			background-color: #fd6a02;
			color: #fff;
			border-radius: 8px;
			// margin: 0.5rem;
			margin-top: 0.5rem;
		}

		.logout {
			font-size: 1.1rem;
			background-color: #fff;
			padding-right: 1.2rem;
			color: #656565;
			cursor: pointer;
			margin-top: 0.35rem;
			text-align: center;
			border: none;
			outline: none;
		}
		a {
			text-decoration: none;
			line-height: 32px;
		}
	}
}

// hamburger for small devices like mobile

@media (min-width: 1151px) {
	.hambuger {
		display: none;
	}
	.navbar-links-mobile {
		display: none;
	}
}

.hambuger {
	.hamburger-icon {
		font-size: 1.6rem;
		cursor: pointer;
		margin-right: 0.8rem;
	}
	.hamburger-logo {
		margin-right: 0.6rem;
		img {
			width: 40px;
			height: 40px;
		}
	}
}

@media (max-width: 1150px) {
	.brand-title {
		display: none;
	}

	.navbar-links {
		display: none;
		width: 100%;
	}

	.navbar-links-mobile {
		width: 50%;
		position: absolute;
		display: block;
		list-style: none;
		background-color: white;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		left: 0;
		top: 0;
		transition: all 0.5s ease-out;
		padding: 2rem;
		height: 100vh;
		overflow: auto;
		ul {
			margin-left: 0.8rem;
			margin-top: 0.5rem;
			font-size: 1.15rem;
			font-weight: 500;
			li {
				list-style: none;
				z-index: 2;
				margin-bottom: 0.8rem;

				a {
					color: black;
					text-decoration: none;
					&:hover {
						color: #fd6a02;
					}
				}
			}
		}
	}
}

// exam dropdown code
.exam-menu {
	position: relative;

	span{
		font-size: 18px;
	}

	ul {
		font-size: 18px;
		cursor: pointer;
	}
	ul:hover {
		color: #fd6a02;
	}

	.exam-dropdown {
		font-size: 15px;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
		list-style: none;
		padding: 0.3rem 0;
		z-index: 1000;

		li {
			padding: 5px 20px;
			border: none;

			a {
				text-decoration: none;
				color: #333;

				&:hover {
					color: #fd6a02;
				}
			}
		}
	}

	&:hover .exam-dropdown {
		display: block;
	}
}
