@import url("https://fonts.googleapis.com/css?family=Poppins");
// * {
//   margin: 0;
//   padding: 0;
//   font-family: Poppins;
// }

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
.footer {
	display: grid;
	// grid-template-columns: repeat(5, 1fr);
	grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
	justify-content: space-evenly;
	background-color: #1e1e1e;
	padding: 2rem;
	color: #fff;
	position: relative;
	bottom: 0;

	@include tablet() {
		grid-template-columns: 1fr 1fr 1fr;
		hr {
			display: none;
		}
	}
	@include smalltablet() {
		grid-template-columns: 1fr 1fr;
		hr {
			display: none;
		}
	}

	@include mobile() {
		grid-template-columns: 1fr;
		hr {
			display: none;
		}
	}

	//left part
	.qick_link {
		// border: 1px solid red;
		padding-left: 2rem;
		padding: 1rem;

		h3 {
			padding-bottom: 1rem;
		}
		ul {
			list-style: none;
			display: grid;
			grid-template-columns: 1fr;

			@include mobile() {
				grid-template-columns: 1fr 1fr;
			}
		}
		ul a {
			text-decoration: none;
			color: #fff;
			font-size: 1.2rem;
			line-height: 2.5rem;
			cursor: pointer;
			@include mobile() {
				line-height: 2rem;
			}
		}
		ul a:hover {
			color: #fd6a02;
		}
	}

	//Middle part
	.find_us {
		// border: 1px solid red;
		padding-left: 2rem;
		padding: 1rem;

		h3 {
			padding-bottom: 1rem;
		}
		.inner_element {
			width: 100%;
			height: 70px;
			background-color: #3f3f3f;
			margin: 1rem 0;
			padding: 1rem 0;
			border-radius: 3px;
			text-decoration: none;
			list-style: none;
			color: #fff;
			cursor: pointer;

			span {
				padding: 1.3rem;
				background-color: #595959;
				border-radius: 3px 0 0 3px;
				margin-right: 1rem;
				font-size: 1.5rem;
				padding-top: 0.9rem;
				text-align: center;
			}
		}
		.inner_element:hover {
			.fbk {
				background-color: #415f99;
			}
			.twt {
				background-color: #69b2e8;
			}
			.lnd {
				background-color: #1c7cb0;
			}
			.ins {
				background-color: #dd3282;
			}
		}

		.facebook:hover {
			background-color: #385999;
			color: #fff;
		}
		.tweeter:hover {
			background-color: #55aced;
			color: #fff;
		}
		.linkedIn:hover {
			background-color: #0275b4;
			color: #fff;
		}
		.insta:hover {
			background-color: #d91d75;
			color: #fff;
		}

		.inner_element a {
			text-decoration: none;
			color: #fff;
			font-size: 1.2rem;
			line-height: 2rem;
		}
	}

	//right part
	.download_app {
		padding-top: 1rem;
		text-align: center;
		@include smalltablet() {
			margin-bottom: 2rem;
		}

		h3 {
			padding-bottom: 1rem;
		}
		.footer-powered {
			font-size: 1rem;
			margin-top: 4rem;
		}

		.img_flex {
			// border: 1px solid red;

			display: grid;
			grid-template-columns: 1fr;

			// @include tablet(){
			//   width: 60%;
			//   height: 50%;
			// }

			// @include smalltablet(){
			//   width: 60%;
			//   height: 40%;
			// }

			// @include mobile1(){
			//   width: 60%;
			//   height: 30%;
			// }

			@include mobile() {
				width: 100%;
				height: 60%;
				grid-template-columns: 1fr 1fr;
			}

			.image1 a img {
				// border: 1px solid red;
				width: 90%;
				height: 70%;
				margin: 1rem 0rem;
				// row-gap: 1rem;
				border-radius: 5px;
			}
			.image2 a img {
				width: 90%;
				height: 70%;
				margin: 1rem 0rem;
				// row-gap: 1rem;
				border-radius: 5px;
			}
		}
	}

	.vrticle_row {
		border: 1px solid #606060;
		width: 0.5px;
		height: 90%;
		margin-left: 10rem;
		margin-top: 2rem;
		bottom: 0;
	}
}
