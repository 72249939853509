* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// Responsive mixins
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

.main_testimonial {
	width: 100%;
	background: #f1f1f1;
	padding: 2rem 3rem;
	margin-bottom: 2rem;

	.heading {
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			height: 320px;
			background-color: #fff;
			padding: 0.5rem;
			border-radius: 1rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			margin: 1rem; // Added margin to space out cards

			// Setting a flexible width to allow for three cards in the first row
			width: calc(33.33% - 2rem); // Three cards with margin in the row

			.useImage {
				width: 121px;
				height: 121px;
				margin-bottom: 1rem;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: fill;
				}
			}

			.userName {
				text-align: center;
				font-size: 1rem;
				font-weight: bold;
			}

			.userDetails {
				font-size: 1rem;
				text-align: justify;
				padding: 0 1rem;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}

	// Responsive styles
	@include smalltablet {
		.container {
			.card {
				width: calc(50% - 2rem); // Two cards in the row on small tablets
			}
		}
	}

	@include mobile1 {
		.container {
			.card {
				width: calc(100% - 2rem); // One card per row on mobile
			}
		}
	}
}

