// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
.pdf-page-break {
	page-break-before: always;
}

.show-result {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: space-around;
	align-items: center;
	gap: 10px;
	background: #f5f5f5;
	margin: 0 auto;
	width: 100%;
	@include smalltablet {
		width: 100%;
	}
	@include mobile1 {
		width: 100%;
	}
	@include mobile {
		width: 100%;
	}
}
.result-image {
	grid-column: span 4;
	margin-left: 30px;
	place-items: center;

	img {
		height: 250px;
		width: 100%;
	}
	@include tablet {
		grid-column: span 6;
		margin-left: 0px;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	@include smalltablet {
		grid-column: span 12;
		margin-left: 0px;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	@include mobile1 {
		grid-column: span 12;
		margin-left: 0px;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	@include mobile {
		grid-column: span 12;
		margin-left: 0px;
	}
}
.back-button {
	button {
		border: none;
		background-color: #fd6a02;
		color: white;
		padding: 0.4rem 1rem;
		font-size: 1.1rem;
		margin: 0.4rem 0;
		border-radius: 10px;
	}
}
.result-score {
	grid-column: span 4;
	// margin-left: 20px;
	justify-self: center;
	text-align: center;
	@include tablet {
		grid-column: span 12;
		place-self: center;
		grid-row-start: 2;
	}
	@include smalltablet {
		grid-column: span 6;
		margin-left: 50px;
	}
	@include mobile1 {
		grid-column: span 12;
		margin-left: 0px;
		text-align: center;
	}
	@include mobile {
		grid-column: span 12;
		margin-left: 0px;
		text-align: center;
	}

	.score {
		font-size: 50px;
	}
	.score-text {
		font-size: 20px;
		font-weight: 400;
		color: #606060;
	}
	// .try-again {
	//   background: #fd6a02;
	//   padding: 8px;
	//   color: #ffff;
	//   border: none;
	//   border-radius: 15px;
	//   height: 45px;
	//   width: 150px;
	//   margin-top: 20px;
	//   font-size: 15px;
	// }
}
.result-details {
	grid-column: span 4;
	margin: 30px 30px;
	background: #ddf0f6;
	border-radius: 6px;

	@include tablet {
		grid-column: span 6;
		place-self: center;
	}
	@include smalltablet {
		grid-column: span 6;
		place-self: center;
	}
	@include mobile1 {
		grid-column: span 12;
		place-self: center;
	}
	@include mobile {
		grid-column: span 12;
		place-self: center;
	}

	.result-details-content-p {
		font-size: 18px;
		padding: 7px 10px;
		color: #595959;
		span {
			font-size: 18px;
			font-weight: 600;
		}
	}
}

// Question and Answer part
.answer-check {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: center;
	background: #f5f5f5;
	margin: 0 auto;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 30px;
	@include mobile1 {
		grid-column: span 12;
		width: 100%;
	}
	@include mobile {
		grid-column: span 12;
		width: 100%;
	}
}

.exam-title {
	grid-column: span 12;
	font-size: 25px;
	font-weight: 600;
	color: #fe0100;
	margin-top: 30px;
	margin-left: 30px;
	margin-bottom: 30px; //
}
.question-answer {
	grid-column: span 12;
	.question,
	.actual-answer,
	.your-answer {
		text-align: justify;
		margin: 10px 30px;
	}
	hr.solid {
		border-top: 1px solid #bbb;
		margin: 10px 30px;
	}
}

.download-pdf-button {
	display: flex;
	justify-content: center;

	button {
		font-size: 1.1rem;
		color: #fff;
		padding: 15px;
		outline: none;
		border: none;
		background-color: #fd6a02;
		border-radius: 15px;
		cursor: pointer;
		margin-bottom: 1.5rem;
	}
	button:hover {
		background-color: #4682b4;
	}
}

// for print code
@media print {
	/* Hide elements not to be printed */
	.back-button,
	.print-button,
	footer {
		/* This ensures the footer is hidden */
		display: none;
	}

	/* Hide navbar if you have one */
	nav {
		display: none; /* Assuming you have a navbar to hide */
	}

	/* Optional: Adjust body styles for printing */
	body {
		margin: 0;
		padding: 0;
	}

	/* Main content area */
	#printableArea {
		page-break-after: always; /* Prevent page breaks if necessary */
	}
}
