* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// for responsive
@mixin mobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin mobile1 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin smalltablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-template-rows: repeat(3, 1fr);
  margin-top: 6rem;
}

.about {
  background: #ffff;
  grid-row: 1;
  grid-column: 1 / 4;
  margin-top: 100px;
  place-self: center;
  padding: 2rem 0;
  width: 80%;
  min-height: 850px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @include mobile() {
    width: 90%;
  }
}
.about-h4,
.about-h5 {
  font-size: 32px;
  font-weight: 500px;
  padding-left: 50px;

  @include mobile() {
    font-size: 20px;
    font-weight: 600;
    padding-left: 1rem;
  }
}
.about-h5 {
  color: #fd6a02;
}
p {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 50px;
  text-align: justify;

  @include mobile() {
    padding: 0 1rem;
    font-size: 16px;
  }
}
.image {
  grid-column: 1/4;
  grid-row: 1/1;

  img {
    width: 100%;
    // height: 300px;
  }
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  @include smalltablet() {
    grid-template-columns: repeat(4, 1fr);
  }
}
.features-heading {
  grid-column: span 4;
  place-self: center;
  margin-top: 50px;
  font-size: 2rem;
  font-weight: 600;
  @include smalltablet() {
    grid-column: span 6;
  }

  @include mobile() {
    grid-column: span 4;
    font-size: 1.3rem;
  }
}
.bottom {
  grid-column: 1 / 2;
  place-self: center;
  text-align: center;
  @include smalltablet() {
    grid-column: 1 / 3;
  }
  @include mobile() {
    // grid-template-columns: 1fr;
    grid-column: span 4;
  }
}

.center {
  grid-column: 2 / 3;
  place-self: center;
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 2rem;
  @include smalltablet() {
    grid-column: 3/6;
    margin-top: 2rem;
  }
  @include mobile() {
    // grid-template-columns: 1fr;
    grid-column: span 4;
    margin-top: 2rem;
  }
}

.side {
  grid-column: 3 /4;
  place-self: center;
  text-align: center;
  @include smalltablet() {
    grid-column: span 6;
  }
  @include mobile() {
    // grid-template-columns: 1fr;
    grid-column: span 4;
  }
}

.features-p {
  font-size: 1.5rem;
  color: #fd6a02;

  @include mobile1(){
    font-size: 1.2rem;
  }
}
