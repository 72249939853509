//this is for BannerImage and trening course components
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// for responsive
@mixin mobile {
	@media only screen and (max-width: 575px) {
		@content;
	}
}
@mixin mobile1 {
	@media only screen and (max-width: 768px) {
		@content;
	}
}
@mixin smalltablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}
@mixin tablet {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

// Home.js
//For Banner Image

// For Trending Courses
.home_container {
	padding: 0 2.5rem;

	@include mobile() {
		padding: 0rem 0.5rem;
	}
	@include mobile1() {
		padding: 0rem 1rem;
	}

	.heading {
		// border: 1px solid red;
		display: flex;
		justify-content: space-between;
		// margin-top: 30px;
		margin-bottom: 20px;

		@include mobile(){
			margin-top: 0;
		}

		.prev_next {
			width: 54px;
			height: 54px;
			border-radius: 50%;
			button {
				width: 54px;
				height: 54px;
				border-radius: 50%;
				border: 2px solid #787878;
				background-color: #fff;
				color: #787878;
				cursor: pointer;
			}
		}
		button:hover {
			background-color: #fd6a02;
			color: #fff;
			border: 2px solid #fd6a02;
		}
		h3 {
			font-family: "Maiden Orange", serif;
			font-size: 32px;
			font-weight: 400;
			line-height: 48px;
			text-align: center;

			@include mobile1() {
				font-size: 26px;
			}
		}
	}

	// trending course
	.tranding_course {
		// border: 2px solid red;
		display: grid;

		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;

		@include tablet() {
			grid-template-columns: repeat(3, 1fr);
		}
		@include smalltablet() {
			grid-template-columns: 1fr 1fr;
		}
		@include mobile1() {
			grid-template-columns: 1fr;
		}
		@include mobile() {
			grid-template-columns: 1fr;
		}

		.course_image {
			// border: 1px solid red;
			height: 449px;
			background-color: #f5f5f5;
			position: relative;
			border-radius: 15px;

			.display_center {
				display: flex;
				justify-content: center;

				img {
					width: 250px;
					height: 250px;
					border-radius: 50%;
					padding: 0rem;
					margin-top: 2rem;
				}
			}

			h6 {
				width: 90%;
				margin: 1rem;
				margin-bottom: 1rem;
				padding: 1rem;
				color: #fff;
				font-size: 1.1rem;
				background-color: #fd6a02;
				text-align: center;
				position: absolute;
				bottom: 0;

				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}

	@keyframes colorEffect {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.course_image:hover {
		h6 {
			display: none;
		}

		.display_center {
			display: flex;
			justify-content: center;

			img {
				width: 150px;
				height: 150px;
				border-radius: 50%;
				padding: 0rem;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}

		.hide_show {
			display: block;
			height: 60%;
			background-color: #fd6a02;
			padding: 1rem 1rem 0 1rem;
			border-radius: 0 0 15px 15px;

			.para_btn {
				height: 100%;
				position: relative;

				.title {
					.trending_title {
						font-size: 1.2rem;
						color: #fff;
						// color: #fe0100;
						font-weight: 600;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				.trending_details {
					height: 68%;
					font-size: 1rem;
					text-align: justify;
					color: #fff;
					display: -webkit-box;
					-webkit-line-clamp: 7;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}

				.buttum {
					// border: 1px solid red;
					display: flex;
					justify-content: space-between;

					.fee {
						display: flex;
						color: #fff;
						font-weight: 600;
						margin-top: 15px;

						span {
							font-size: 1.1rem;
						}
					}

					button {
						margin-top: 7px;
						padding: 8px;
						background-color: #fff;
						color: #fd6a02;
						border-radius: 4px;
						cursor: pointer;
						border: none;
						outline: none;
						align-self: flex-end;
						margin-top: 0.5rem;
					}
				}
			}
		}
	}
}
