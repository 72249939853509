.pagination {
	display: flex;
	button {
		background-color: white;
		color: black;
		padding: 0.3rem;
		border: none;
		&:disabled {
			background-color: #fd6a02;
			color: white;
		}
	}

	.pagination-prev {
		button {
			background-color: #fd6a02;
			color: white;
			border: 1px solid #fd6a02;

			&:disabled {
				background-color: white;
				color: black;
			}
		}
	}
}
.previous {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.next {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
